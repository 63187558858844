$(document).ready(function () {

    let $priceRangeSlider = $('#sliderInput');
    let $searchForm = $('#searchForm');

    if ($priceRangeSlider.length) {
        $priceRangeSlider.slider({});
    }

    if ($searchForm.length) {
        $searchForm.on('submit', function () {
            let url = $(this).data('url');
            let keyword = $('input.search').val();
            url = url.replace("{keyword}", keyword);
            window.open(url, '_blank');
        });
    }

    // Prevent closing from click inside dropdown
    $(document).on('click', '.dropdown-menu', function (e) {
        e.stopPropagation();
    });

    // make it as accordion for smaller screens
    if ($(window).width() < 992) {
        $('a.dropdown-toggle').click(function(e){
            console.log("dropdown-menu .dropdown-toggle a");
            e.preventDefault();
            if($(this).next('.submenu').length){
                $(this).next('.submenu').toggle();
            }
            $('.dropdown').on('hide.bs.dropdown', function () {
                $(this).find('.submenu').hide();
            })
        });
    }

});
